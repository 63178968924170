/**
 * 节流函数
 * @template T
 * @param {T} cb - 需要节流的函数
 * @param {number} [wait] - 节流时间，默认值300ms
 * @returns {T} 需要节流的函数
 */
export function throttle(cb, wait = 300) {
  let last = 0;
  return function (...cbArgs) {
    var now = new Date().getTime();
    if (now - last > wait) {
      cb.apply(this, cbArgs);
      last = new Date().getTime();
    }
  }
}