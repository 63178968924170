<template>
  <ul>
    <li
      v-for="i in count"
      :key="i"
      :class="{ active: i - 1 == nowIndex }"
      @click="anchorPointClick(i - 1)"
    >
      <div class="anchor"></div>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  count: Number,    //有多少个点
  nowIndex: Number  //当前激活第几个点（索引）
})
const emit = defineEmits(['anchorPointClick'])

function anchorPointClick(index) {
  emit('anchorPointClick', index)
}
</script>

<style scoped lang="scss">
ul {
  @mixin li_hover {
    //定义li的鼠标hover样式，与active时复用
    padding: 6px;
    cursor: pointer;

    div.anchor {
      background-color: rgba(25,83,166,0);
      border: 2px solid #ffffff;
    }
  }

  li {
    transition: 0.3s;
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    padding: 9.5px;

    &:hover {
      @include li_hover;
    }

    div.anchor {
      transition: 0.3s;
      height: 100%;
      width: 100%;
      background-color: rgba(255,255,255,0.32);
      border-radius: 100%;
    }
  }

  .active {
    @include li_hover;
  }
}
</style>