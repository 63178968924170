import { ref, onMounted, reactive } from "vue";

/**
 * hook：监听某标签鼠标滚轮事件
 * @param {*} domSelector 
 * @returns 
 */
export const useScrollListen = (domSelector) => {
  const callback = reactive({
    /** 鼠标滚轮下滚的callback */
    pagedownCb: null,
    /** 鼠标滚轮上滚的callback */
    pageupCb: null
  })

  onMounted(() => {
    document.querySelector(domSelector).addEventListener("mousewheel", e => {
      if (e.deltaY > 0) {
        callback.pagedownCb && callback.pagedownCb()
      }
      else if (e.deltaY < 0) {
        callback.pageupCb && callback.pageupCb()
      }
    })

    //火狐兼容
    document.querySelector(domSelector).addEventListener("DOMMouseScroll", e => {
      if (e.deltaY > 0) {
        callback.pagedownCb && callback.pagedownCb()
      }
      else if (e.deltaY < 0) {
        callback.pageupCb && callback.pageupCb()
      }
    })
  })

  return {
    callback
  }
}